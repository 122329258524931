import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const Login = () => import(/* webpackChunkName: 'Login' */ '../views/login/index')
const Main = () => import(/* webpackChunkName: 'cashier-main' */ '../components/cashier-main/cashierMain')
const membertab = () => import(/* webpackChunkName: 'member' */ '../views/member/membertab')
const goodList = () => import(/* webpackChunkName: 'goods' */ '../views/goods/goodList')
const index = () => import(/* webpackChunkName: 'admin' */ '../admin/index')
const memberList = () => import(/* webpackChunkName: 'admin' */ '../admin/member/memberList')
const memberDetails = () => import(/* webpackChunkName: 'admin' */ '../admin/member/memberDetails')
const home = () => import(/* webpackChunkName: 'admin' */ '../admin/home')
const management = () => import(/* webpackChunkName: 'admin' */ '../admin/oderform/management')
const hire = () => import(/* webpackChunkName: 'admin' */ '../admin/hireGoods/hire')
const orderDetails = () => import(/* webpackChunkName: 'admin' */ '../admin/oderform/orderDetails')
const something = () => import(/* webpackChunkName: 'admin' */ '../admin/returningGoods/something')
const orderReturn = () => import(/* webpackChunkName: 'admin' */ '../admin/returningGoods/orderReturn')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
} // 重复点击路由报错的问题
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: 'login' },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  // {
  //   path: '/Home',
  //   name: 'Home',
  //   component: Home,
  //   children: [
  //     { path: '/main', component: Main, name: 'main' },
  //     { path: '/membertab', component: membertab, name: 'membertab' },
  //     { path: '/goodList', component: goodList, name: 'goodList' },
  //   ],
  // },
  {
    path: '/index',
    name: 'index',
    component: index,
    children: [
      { path: '/home', component: home, name: 'home' },
      { path: '/memberList', component: memberList, name: 'memberList' },
      { path: '/memberDetails', component: memberDetails, name: 'memberDetails' },
      { path: '/management', component: management, name: 'management' },
      { path: '/hire', component: hire, name: 'hire' },
      { path: '/orderDetails', component: orderDetails, name: 'orderDetails' },
      { path: '/something', component: something, name: 'something' },
      { path: '/orderReturn', component: orderReturn, name: 'orderReturn' },
    ],
  },
]

const router = new VueRouter({
  routes,
})

export default router
