<template>
  <div class="container">
    <cashierAside />
    <router-view />
    <cashierInfo />
  </div>
</template>


<script>
import cashierMain from '../components/cashier-main/cashierMain.vue'
import cashierAside from '../components/cashier-aside/cashierAside.vue'
import cashierInfo from '../components/cashier-info/cashierInfo.vue'
export default {
  components: {
    cashierInfo,
    cashierAside,
    cashierMain
  }
}
</script>