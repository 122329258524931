<template>
  <main>
    <h1>Analytics 多多兔 - 收银系统</h1>
    <!-- Analyses -->
    <div class="analyse">
      <div class="sales">
        <div class="status">
          <div class="info">
            <h3>Total Sales 新增会员</h3>
            <h1>$65,024</h1>
          </div>
          <div class="progresss">
            <svg>
              <circle cx="38" cy="38" r="36"></circle>
            </svg>
            <div class="percentage">
              <p>+81%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="visits">
        <div class="status">
          <div class="info">
            <h3>Site Visit 租玩具</h3>
            <h1>24,981</h1>
          </div>
          <div class="progresss">
            <svg>
              <circle cx="38" cy="38" r="36"></circle>
            </svg>
            <div class="percentage">
              <p>-48%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="searches">
        <div class="status">
          <div class="info">
            <h3>Searches 还玩具</h3>
            <h1>14,147</h1>
          </div>
          <div class="progresss">
            <svg>
              <circle cx="38" cy="38" r="36"></circle>
            </svg>
            <div class="percentage">
              <p>+21%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="searches">
        <div class="status">
          <div class="info">
            <h3>Searches 卖玩具</h3>
            <h1>14,147</h1>
          </div>
          <div class="progresss">
            <svg>
              <circle cx="38" cy="38" r="36"></circle>
            </svg>
            <div class="percentage">
              <p>+21%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Analyses -->

    <!-- New Users Section -->
    <div class="new-users">
      <h2>New Users 新增会员</h2>
      <div class="user-list">
        <div class="user">
          <img src="../../assets/images/profile-2.jpg">
          <h2>Jack</h2>
          <p>54 Min Ago</p>
        </div>
        <div class="user">
          <img src="../../assets/images/profile-3.jpg">
          <h2>Amir</h2>
          <p>3 Hours Ago</p>
        </div>
        <div class="user">
          <img src="../../assets/images/profile-4.jpg">
          <h2>Ember</h2>
          <p>6 Hours Ago</p>
        </div>
        <div class="user" @click="addUserVIP">
          <img src="../../assets/images/plus.png">
          <h2>More</h2>
          <p>New User</p>
        </div>
      </div>
    </div>
    <!-- End of New Users Section -->

    <!-- Recent Orders Table -->
    <!-- <div class="recent-orders">
      <h2>Recent Orders 订单详情</h2>
      <table>
        <thead>
          <tr>
            <th>Course Name</th>
            <th>Course Number</th>
            <th>Payment</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <th>1</th>
            <th>1</th>
            <th>1</th>
          </tr>
          <tr>
            <th>1</th>
            <th>1</th>
            <th>1</th>
            <th>1</th>
          </tr>
        </tbody>
      </table>
      <a href="#">Show All</a>
    </div> -->
    <!-- End of Recent Orders -->
    <el-dialog title="添加会员" :visible.sync="dialogVisible" width="50%">
      <p class="vip">开卡时间</p>
      <el-date-picker :unlink-panels='true' v-model="time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <p class="vip">押金</p>
      <el-input v-model="deposit" type="number" placeholder="请输入内容"></el-input>
      <p class="vip">手机号</p>
      <el-input v-model="phone" type="number" placeholder="请输入内容"></el-input>
      <ul class="fukuantype">
        <li>
          <img src="../../assets/images/01.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/02.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/03.png" alt="">
        </li>
      </ul>
      <el-radio-group v-model="pay_type">
        <el-radio :label="3">支付宝</el-radio>
        <el-radio :label="2">微信</el-radio>
        <el-radio :label="1">现金</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </main>
</template>



<script>
export default {
  data() {
    return {
      dialogVisible: false,
      time: '',
      deposit: '',
      phone: '',
      pay_type: 3
    }
  },
  methods: {
    addUserVIP() {
      this.dialogVisible = true
    },
    async submit() {
      const res = await this.axios.post('cashier/user_vip', {
        start_time: this.time[0],
        end_time: this.time[1],
        deposit: this.deposit,
        phone: this.phone,
        pay_type: this.pay_type
      })
      if (res.code === 200) {
        this.dialogVisible = false
        this.$message.success('会员开通成功');
      } else {
        this.$message.error(res.message);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-date-editor--monthrange.el-input,
.el-date-editor--monthrange.el-input__inner {
  width: 415px;
}
.vip {
  margin-top: 20px;
}
.fukuantype {
  display: flex;
  margin-top: 30px;
  li {
    margin-right: 35px;
    padding-left: 5px;
    img {
      width: 50px;
    }
  }
}
</style>