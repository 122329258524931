<template>
  <aside>
    <div class="toggle">
      <div class="logo">
        <img src="../../assets/images/logo.png">
        <h2>Asmr<span class="danger">Prog</span></h2>
      </div>
      <div class="close" id="close-btn">
        <span class="material-icons-sharp">
          close
        </span>
      </div>
    </div>
    <div class="sidebar">
      <a :class="{active:index === seIndex}" v-for="(item,index) in menuList" :key="item.id" @click="changeRouter(item.path,index)">
        <span class="material-icons-sharp">
          <!-- {{item.icon}} -->
        </span>
        <h3>{{item.text}}</h3>
      </a>
      <!-- <a>
        <span class="material-icons-sharp">
          dashboard
        </span>
        <h3>商品列表</h3>
      </a>
      <a @click="$router.push({path:'/membertab'})">
        <span class="material-icons-sharp">
          person_outline
        </span>
        <h3>会员列表</h3>
      </a>
      <a href="#" class="active">
        <span class="material-icons-sharp">
          insights
        </span>
        <h3>Analytics</h3>
      </a>
      <a href="#">
        <span class="material-icons-sharp">
          mail_outline
        </span>
        <h3>Tickets</h3>
        <span class="message-count">27</span>
      </a>
      <a href="#">
        <span class="material-icons-sharp">
          inventory
        </span>
        <h3>Sale List</h3>
      </a>
      <a href="#">
        <span class="material-icons-sharp">
          report_gmailerrorred
        </span>
        <h3>Reports</h3>
      </a>
      <a href="#">
        <span class="material-icons-sharp">
          settings
        </span>
        <h3>Settings</h3>
      </a>
      <a href="#">
        <span class="material-icons-sharp">
          add
        </span>
        <h3>New Login</h3>
      </a> -->
      <a href="#">
        <span class="material-icons-sharp">
          logout
        </span>
        <h3>Logout</h3>
      </a>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          id: 1,
          path: '/main',
          text: '收银台',
          icon: 'receipt_long'
        },
        {
          id: 2,
          path: '/goodList',
          text: '商品列表',
          icon: 'dashboard'
        },
        {
          id: 3,
          path: '/membertab',
          text: '会员列表',
          icon: 'person_outline'
        }
      ],
      seIndex: 0
    }
  },
  methods: {
    changeRouter(path, index) {
      this.seIndex = index
      this.$router.push({ path: path })
    }
  }
}
</script>




<style lang="less" scoped>
a {
  cursor: pointer;
}
</style>