import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style/base.css'
// import './index.js'
import axios from 'axios'

Vue.prototype.axios = axios
axios.defaults.baseURL = 'https://duoduotu.zbfib.com/api/'
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 401) {
      router.push({ path: '/login' })
    }
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)
Vue.prototype.getQueryValue = function (name) {
  var url = window.location.href
  var result = url.match(new RegExp('[?&]' + name + '=([^&]+)', 'i'))
  if (result == null || result.length < 1) {
    return ''
  }
  return decodeURI(result[1])
}

Vue.config.productionTip = false

Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
