<template>
  <div class="right-section">
    <div class="nav">
      <button id="menu-btn">
        <span class="material-icons-sharp">
          menu
        </span>
      </button>
      <div class="dark-mode">
        <!-- <span class="material-icons-sharp active">
          light_mode
        </span> -->
        <!-- <span class="material-icons-sharp">
          dark_mode
        </span> -->
      </div>

      <div class="profile">
        <div class="info">
          <p>Hey, <b>Reza</b></p>
          <small class="text-muted">Admin</small>
        </div>
        <div class="profile-photo">
          <img src="../../assets/images/profile-1.jpg">
        </div>
      </div>

    </div>
    <!-- End of Nav -->

    <div class="user-profile" @click="searchForVIP">
      <div class="logo">
        <img src="../../assets/images/logo.png">
        <h2>AsmrProg会员租玩具</h2>
        <p>Fullstack Web Developer</p>
      </div>
    </div>
    <div class="user-profile" @click="returntoy">
      <div class="logo">
        <h2>归还玩具</h2>
        <p>Fullstack Web return</p>
      </div>
    </div>

    <div class="reminders">
      <div class="header">
        <h2>Reminders 备注</h2>
        <span class="material-icons-sharp">
          notifications_none
        </span>
      </div>

      <div class="notification">
        <div class="icon">
          <span class="material-icons-sharp">
            volume_up
          </span>
        </div>
        <div class="content">
          <div class="info">
            <h3>Workshop</h3>
            <small class="text_muted">
              08:00 AM - 12:00 PM
            </small>
          </div>
          <span class="material-icons-sharp">
            more_vert
          </span>
        </div>
      </div>

      <div class="notification deactive">
        <div class="icon">
          <span class="material-icons-sharp">
            edit
          </span>
        </div>
        <div class="content">
          <div class="info">
            <h3>Workshop</h3>
            <small class="text_muted">
              08:00 AM - 12:00 PM
            </small>
          </div>
          <span class="material-icons-sharp">
            more_vert
          </span>
        </div>
      </div>

      <div class="notification add-reminder">
        <div>
          <span class="material-icons-sharp">
            add
          </span>
          <h3>Add Reminder 添加备注</h3>
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <el-dialog :visible.sync="dialogVisibleToy" width="70%">
      <div class="goods-lis">
        <div class="good-box" v-for="item in zujieGoods" :key="item.id">
          <div class="top">
            <img :src="item.image" alt="">
          </div>
          <div class="inof">
            <p>name:{{item.name}}</p>
            <p>Time:{{item.created_at}}</p>
            <el-button type="primary" round size="small" @click="guiHuanWanJu(item.id)">归还</el-button>
          </div>
        </div>
      </div>

    </el-dialog>
    <!-- 拍照 -->
    <el-dialog :visible.sync="dialogCamera" width="70%" :before-close="handleClose">
      <div class="content" v-loading="loading">
        <video ref="video" width="400" height="300" autoplay></video>
        <canvas ref="canvas" width="400" height="300"></canvas>
      </div>
      <div class="ul">
        <transition name="el-zoom-in-center" v-for="(item,index) in imageList" :key="index">
          <div class="img-box">
            <img :src="item" alt="">
          </div>
        </transition>
      </div>
      <div class="btn-box">
        <el-button @click="photograph">拍摄</el-button>
        <el-button type="primary" v-if="dialogScan" @click="continueCode">继续扫码</el-button>
        <el-button type="success" @click="submitGoods">提交</el-button>
      </div>
    </el-dialog>
    <!-- 扫描条形码 -->
    <el-dialog :visible.sync="dialogScan" title="扫码">
      <img class="scanImage" src="../../assets/images/southeast.jpeg" alt="">
      <el-input ref="inputcode" v-model="scanCode" placeholder="请输入条形码下方数字或扫描条形码" @keyup.enter.native="searchToy"></el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibleToy: false,
      dialogCamera: false,
      dialogScan: false,
      user_id: '',
      imageList: [],
      loading: false,
      scanCode: '',
      goods: {},
      goodList: [],
      zujieGoods: [],
      zjGoodsId: '',
      phone: ''
    }
  },
  mounted() { },
  methods: {
    // 租借玩具
    searchForVIP() {
      this.$prompt('请输入手机号查找会员信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.getuser(value, 1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 归还玩具
    returntoy() {
      this.$prompt('请输入会员号查找订单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.getuser(value, 2)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 调用摄像头
    callCamera() {
      this.loading = true
      navigator.mediaDevices.getUserMedia({
        video: true
      }).then(success => {
        this.$refs['video'].srcObject = success
        this.$refs['video'].play()
        this.loading = false
      }).catch(error => {
        console.error('请检查摄像头是否可用')
      })
    },
    // 拍照
    photograph() {
      this.loading = true
      let ctx = this.$refs['canvas'].getContext('2d')
      ctx.drawImage(this.$refs['video'], 0, 0, 400, 300);
      let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg');
      this.uploadImage(imgBase64)
    },
    // 关闭摄像头
    closeCamera() {
      if (!this.$refs['video'].srcObject) {
        return false;
      }
      let stream = this.$refs['video'].srcObject
      let tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this.$refs['video'].srcObject = null
    },
    // 关闭拍照莫态框
    handleClose() {
      this.closeCamera()
      this.dialogCamera = false
    },
    // 手机号查询会员
    async getuser(value, num) {
      console.log(value)
      const res = await this.axios.get(`cashier/user/phone?phone=${value}`)
      if (res.code === 200) {
        this.user_id = res.data.id
        if (num === 1) {   // 1 租玩具
          this.dialogScan = true
          setTimeout(() => {
            this.$refs.inputcode.focus()
          }, 500)
          // this.callCamera()
        } else {
          this.getHire(value)
        }
      } else {
        this.$message.error('未找到该会员用户，请核实');
      }
    },
    // 扫码
    async searchToy() {
      const res = await this.axios.get(`cashier/good/${this.scanCode}`)
      if (res.code === 200) {
        console.log(this.goodList.filter(item => item.id === this.goods.id))
        if (this.goodList.filter(item => item.id === this.goods.id).length > 0) {
          this.$message.error('请勿重复扫描');
          this.scanCode = ''
          this.repeatGoods()
          return
        }
        this.goods = res.data
        this.goods.bar_code = this.scanCode
        this.scanCode = ''
        this.dialogCamera = true
        this.callCamera()
      } else {
        this.$message.error('商品不存在')
        this.scanCode = ''
      }
    },
    // 拍照上传
    async uploadImage(imgBase64) {
      const res = await this.axios.post('cashier/base64/upload', {
        image: imgBase64
      })
      if (res.code === 200) {
        this.$message.success('成功')
        this.imageList.push(res.data)
        this.loading = false
      } else {
        this.$message.error('失败！请从新拍照');
      }
    },
    continueCode() {
      this.goods.uploadImages = this.imageList
      this.goodList.push(this.goods)
      this.imageList = []
      this.dialogCamera = false
      this.$refs.inputcode.focus()
    },
    submitGoods() {
      if (this.dialogScan) {
        this.hireGoods()
      } else {
        this.confirmGuiHuan()
      }
    },
    async hireGoods() {
      let goods = []
      this.goods.uploadImages = this.imageList
      this.goodList.push(this.goods)
      this.goodList.forEach(item => {
        goods.push({ good_id: item.id, bar_code: item.bar_code, images: item.uploadImages })
      })
      const res = await this.axios.post('cashier/hire', {
        user_id: this.user_id,
        goods
      })
      if (res.code === 200) {
        this.imageList = []
        this.dialogCamera = false
        this.dialogScan = false
        this.$message.success('成功')
      } else {
        this.$message.error(res.message)
      }
    },
    // 处理重复商品逻辑
    repeatGoods() {
      this.$confirm('是否结单', '提示:请务重复扫描', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.submitGoods()
      }).catch(() => {
      });
    },
    async getHire(value) {
      if (value) this.phone = value
      const res = await this.axios.get(`cashier/hire?limit=10&page=1&phone=${this.phone}`)
      console.log(res)
      if (res.code === 200) {
        this.zujieGoods = []
        this.dialogVisibleToy = true
        let list = res.data.list
        list.forEach(item => {
          item.info.forEach(val => {
            if (val.status == 1) {
              this.zujieGoods.push(val)
            }
          })
        })
        console.log(this.zujieGoods)
      }
    },
    async guiHuanWanJu(id) {
      this.zjGoodsId = id
      this.dialogCamera = true
      this.callCamera()
    },
    async confirmGuiHuan() {
      this.$confirm('是否扣除押金', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '不扣除',
        type: 'warning'
      }).then(() => {
        this.deposit()
      }).catch(() => {
        this.apiGuiHuan(0)
      });

    },
    deposit() {
      this.$prompt('请输入扣除金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.apiGuiHuan(value)
      }).catch(() => {
        this.$confirm('是否直接归还', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.apiGuiHuan(0)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消'
          });
        });
      });
    },
    async apiGuiHuan(value) {
      const res = await this.axios.post(`cashier/back/hire/${this.zjGoodsId}`, {
        back_images: this.imageList,
        back_deposit: value * 100
      })
      if (res.code === 200) {
        this.$message.success('归还成功')
        this.imageList = []
        this.dialogCamera = false
        console.log(this.zujieGoods.length)
        if (this.zujieGoods.length === 1) {
          this.dialogVisibleToy = false
        } else {
          this.getHire()
        }
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  // background: transparent;
  .btn-box {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .content {
    display: flex;
    margin-bottom: 28px;
  }
  .ul {
    display: flex;
    flex-wrap: wrap;
    .img-box {
      width: 200px;
      height: 150px;
      margin-right: 20px;
    }
  }
  .scanImage {
    width: 200px;
    margin: auto;
  }
}

.goods-lis {
  display: flex;
  flex-wrap: wrap;
}
.good-box {
  width: 200px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: -1px 6px 21px 0px rgba(139, 137, 137, 0.75);
  .top {
    height: 140px;
    overflow: hidden;
  }
  .inof {
    padding: 10px;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .el-button {
    float: right;
    margin-top: 20px;
  }
}
video {
  margin-right: 20px;
}
</style>

